import React from 'react'
import Layout from '../components/Layout'
import { Link } from 'gatsby'
import BlogRoll from '../components/BlogRoll'

const BlogListTemplate = ({
  posts,
  isFirstPage,
  isLastPage,
  prevPage,
  nextPage,
  numPages,
  currentPage,
}) => {
  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <h3 className="title is-size-2 has-text-weight-semibold ">
                Blog&nbsp;            
                <a
                   href="feed://www.neederland.com/rss.xml"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="icon">
                  <div 
                 className="RSSButton"
                  ></div>
                </div>
                
                </a>
              </h3>
              <BlogRoll posts={posts} />
              <ul
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  alignItems: 'center',
                  listStyle: 'none',
                  padding: 0,
                  margin: '1em 0',
                }}
              >
                {!isFirstPage && (
                  <Link to={prevPage} rel="prev" className='previous'>
                    ← Previous Page
                  </Link>
                )}
                {Array.from({ length: numPages }, (_, i) => (
                  <li
                    key={`pagination-number${i + 1}`}
                    style={{
                      margin: 0,
                    }}
                  >
                    <Link className='pagination'
                      to={`/blog/${i === 0 ? '' : i + 1}`}
                      style={{
                        padding: '10px',
                        textDecoration: 'none',
                        color: i + 1 === currentPage ? '#ffffff' : '',
                        background: i + 1 === currentPage ? '#F57E4D' : '',
                      }}
                    >
                      {i + 1}
                    </Link>
                  </li>
                ))}
                {!isLastPage && (
                  <Link to={nextPage} rel="next" className='next'>
                    Next Page →
                  </Link>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const BlogListPage = ({ data, pageContext }) => {
  const posts = data.allMarkdownRemark.edges
  const { currentPage, numPages } = pageContext
  const isFirstPage = currentPage === 1
  const isLastPage = currentPage === numPages
  const prevPage = `/blog/${
    currentPage - 1 === 1 ? '' : (currentPage - 1).toString()
  }`
  const nextPage = `/blog/${(currentPage + 1).toString()}`

  return (
    <Layout>
      <BlogListTemplate
        posts={posts}
        isFirstPage={isFirstPage}
        isLastPage={isLastPage}
        prevPage={prevPage}
        nextPage={nextPage}
        numPages={numPages}
        currentPage={currentPage}
      />
    </Layout>
  )
}

export default BlogListPage

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
    ) {
      edges {
        node {
          fields {
            slug
            readingTime {
              text
            }
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
            tags
            featuredpost
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
