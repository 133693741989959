import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'

class BlogRoll extends React.Component {
  render() {
    const { posts } = this.props

    return (
      <div className="columns is-multiline">
        {posts &&
          posts.map(({ node: post }) => (
            <div className="is-parent column is-6" key={post.id}>
              <Link to={post.fields.slug}>
                <article
                  className={`blog-list-item tile is-child box notification ${
                    post.frontmatter.featuredpost ? 'is-featured' : ''
                  }`}
                >
                  {post.frontmatter.featuredimage ? (
                    <div className="featured-thumbnail">
                      <PreviewCompatibleImage
                        imageInfo={{
                          image: post.frontmatter.featuredimage,
                          alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                        }}
                      />
                    </div>
                  ) : null}
                  <p className="post-meta">
                    <p className="article__tags">
                      {post.frontmatter.tags.map((tag, i) => [
                        <strong key={i}>
                          {tag}
                          {i < post.frontmatter.tags.length - 1 ? ' ' : ''}
                        </strong>,
                      ])}
                    </p>

                    <span>
                      {' '}
                      <Link
                        className="title has-text-primary is-size-4"
                        to={post.fields.slug}
                      >
                        {post.frontmatter.title}
                      </Link>
                    </span>

                    <span className="subtitle is-size-5 is-block">
                      {post.frontmatter.date}&nbsp;᛫&nbsp;
                      {post.fields.readingTime.text}
                    </span>
                  </p>
                </article>
              </Link>
            </div>
          ))}
      </div>
    )
  }
}

BlogRoll.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.node),
}

export default BlogRoll
